import React from "react";
import AddCircle from "assets/vectors/crm/add_circle.svg";
import WhiteTick from "assets/vectors/white_tick.svg";
import FilterGrey from "assets/vectors/crm/filter_list_grey.svg";
import DeleteRed from "assets/vectors/delete_red.svg";
import { Card } from "@my-scoot/component-library-legacy";
import FilterEmpty from "assets/images/crmv2/filter_empty.svg";
import FilterEmptyMobile from "assets/images/crmv2/filter_empty_mobile.svg";
import { filterLabelMapping } from "webpage-leads/components/CrmModals/AddCustomFilterModal/addCustomFilterModalHelpers";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import ExlyImage from "common/Components/ExlyImage";
import { returnFilterValue } from "ui/pages/customers/CrmDetails/crmDetailsHelpers";
import useWebpageLeads from "webpage-leads/useWebpageLeads";
import constants from "constants/constants";
import useStyles from "./customerFiltersStyle.styles";
import ExlyLoader from "ui/modules/ExlyLoader";

export const BreadrCrumb = ({ title, setFilterPopover }) => {
  const classes = useStyles();

  const bookingsTitle = (
    <span className={classes.page_title}>
      <span onClick={() => setFilterPopover(false)} className="pointer">
        {title}
      </span>
    </span>
  );

  return (
    <>
      {
        <div className={classes.nav_wrap_customer_list}>
          <IconButton
            className={classes.nav_back_btn}
            onClick={() => setFilterPopover(false)}
          >
            <ArrowBackRoundedIcon />
          </IconButton>
          {bookingsTitle}
        </div>
      }
    </>
  );
};

export const EmptyFilterState = ({
  setOpenFiltersModal,
  classes,
  isDesktop,
  entityType,
}) => (
  <>
    <div className={classes.menuOptionsEmpty}>
      <div className={classes.emptyFilterWrapper}>
        <div className={classes.leftDiv}>
          <p className={classes.header}>No filters yet.</p>
          <p className={classes.emptyStatePara}>
            Easily customize to filter out your desired{" "}
            {entityType === constants.entity_type.leads.value
              ? constants.entity_type.leads.label
              : constants.entity_type.customers.label}
            . You can also add multiple filters to create various combinations.
          </p>
          <AddFilter setOpenFiltersModal={setOpenFiltersModal} />
        </div>
        <div>
          <ExlyImage
            src={isDesktop ? FilterEmpty : FilterEmptyMobile}
            alt={"FilterEmpty"}
          />
        </div>
      </div>
    </div>
  </>
);

export const FiltersToApply = ({
  setOpenFiltersModal,
  isDesktop,
  customFiltersValue,
  applyFilters,
  handleDelete,
  tagsArray,
  openEditFiltersModal,
  loading,
}) => {
  const { orgMembers } = useWebpageLeads();

  const classes = useStyles({ isDesktop });

  return (
    <div className={classes.filtersPopoverWrapper}>
      <div className={classes.filterHeader}>
        ALL FILTERS <ExlyImage src={FilterGrey} alt={"FilterGrey"} />
      </div>
      <div className={classes.filterBodyWrapper}>
        {loading ? (
          <ExlyLoader />
        ) : (
          <>
            {customFiltersValue.map((filter, idx) => (
              <div key={filter.id} className={classes.cardDeleteWrapper}>
                <Card
                  variant="outlined"
                  className={classes.filterCard}
                  CardClassName={classes.filterCard}
                  onClick={() => {
                    openEditFiltersModal(filter, idx);
                  }}
                >
                  <span className={classes.bold}>
                    {filterLabelMapping[filter?.field]}{" "}
                  </span>{" "}
                  {filter?.listing && (
                    <div>
                      <span>of </span>
                      <span className={classes.blueText}>
                        {filter.listing?.label}
                      </span>
                    </div>
                  )}
                  {filter?.question && (
                    <div>
                      <span>for </span>
                      <span className={classes.lightBlueText}>
                        {filter.question?.label}
                      </span>
                    </div>
                  )}
                  includes{" "}
                  <span
                    className={
                      filter?.question ? classes.greenText : classes.blueText
                    }
                  >
                    {" "}
                    {returnFilterValue(filter, orgMembers, tagsArray)
                      ?.toString()
                      .replaceAll(",", " , ")}
                  </span>
                </Card>
                <div
                  onClick={() => {
                    handleDelete(idx);
                  }}
                  className={classes.deleteWrapper}
                >
                  <ExlyImage src={DeleteRed} alt={"DeleteRed"} />
                </div>
              </div>
            ))}
            <AddFilter setOpenFiltersModal={setOpenFiltersModal} />
          </>
        )}
      </div>
      <div className={!isDesktop && classes.footerWrapperDiv}>
        <footer
          className={classes.filterFooterWrapper}
          onClick={() => applyFilters()}
        >
          <ExlyImage src={WhiteTick} alt={"WhiteTick"} /> Apply Filters (
          {customFiltersValue?.length})
        </footer>
      </div>
    </div>
  );
};

export const AddFilter = ({ setOpenFiltersModal }) => {
  const classes = useStyles();

  return (
    <div onClick={() => setOpenFiltersModal()}>
      <div className={classes.addFilter}>
        <ExlyImage src={AddCircle} alt={"AddCircle"} />
        Add Filter{" "}
      </div>
    </div>
  );
};
