/**
 * This function taks a s3 url, and returns the file key from the url
 * @param {string} urlArg - The complete url of the s3 file
 * @returns the s3 key string of the whole url.
 * "undefined" is reurned if argument is not a proper URL
 */
export const getS3ItemKey = (urlArg) => {
  try {
    const url = new URL(urlArg);
    const { pathname } = url;
    return pathname.substring(1);
  } catch (err) {
    console.log("error while parsing the url: ", err);
    return undefined;
  }
};
