import Email from "assets/vectors/crm/mail.svg";
import Call from "assets/vectors/crm/call.svg";
import Assignee from "assets/vectors/crm/support_agent.svg";
import Status from "assets/vectors/crm/frame_person.svg";
import Source from "assets/vectors/crm/assistant.svg";
import Link from "assets/vectors/crm/link.svg";
import Responses from "assets/vectors/crm/live_help.svg";
import Tags from "assets/vectors/crm/label_important.svg";
import Location from "assets/vectors/crm/explore.svg";
import Rupee from "assets/vectors/crm/currency_rupee.svg";
import LastTransaction from "assets/vectors/crm/acute.svg";
import NoOfTransaction from "assets/vectors/crm/receipt_long.svg";
import GpsFixed from "@material-ui/icons/GpsFixed";
import DateRangeIcon from "@material-ui/icons/DateRange";

import { updateColumns } from "webpage-leads/webpageHelpers";
import constants from "constants/constants";
import { customer_table_config } from "features/Crm/modules/AllCustomers/AllCustomers.constants";
import { leads_table_config } from "features/Crm/modules/WebpageLeads/WebpageLeads.constants";
import { getCrmAsset } from "features/Crm/utils/Crm.utils";
import { logError } from "utils/error";

export const allColumnsArrayLeads = [
  {
    id: 0,
    label: "EMAIL",
    img: Email,
    disabled: true,
    isSelected: true,
    isLocked: true,
    value: leads_table_config.email.field,
  },
  {
    id: 1,
    label: "PHONE NO.",
    img: Call,
    disabled: true,
    isSelected: true,
    isLocked: true,
    value: leads_table_config.phone_number.field,
  },
  {
    id: 2,
    label: "ASSIGNEE",
    img: Assignee,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: leads_table_config.org__uuid.field,
  },
  {
    id: 3,
    label: "STATUS",
    img: Status,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: leads_table_config.status.field,
  },
  {
    id: 4,
    label: "LAST ACTIVITY DATE",
    img: getCrmAsset("save_as.svg"),
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: leads_table_config.last_occurrence_time.field,
  },
  {
    id: 5,
    label: "LAST ACTIVITY",
    img: getCrmAsset("chronic.svg"),
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: leads_table_config.reason.field,
  },
  {
    id: 6,
    label: "ADDED ON",
    icon: DateRangeIcon,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: leads_table_config.first_occurrence_time.field,
  },
  {
    id: 7,
    label: "SOURCE",
    img: Source,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: leads_table_config.referer_group_id.field,
  },
  {
    id: 8,
    label: "SOURCE LINK",
    img: Link,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: leads_table_config.source_link.field,
  },
  {
    id: 9,
    label: "RESPONSES",
    img: Responses,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: leads_table_config.answer_count.field,
  },
  {
    id: 10,
    label: "TAGS",
    img: Tags,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: leads_table_config.tags.field,
  },
  {
    id: 11,
    label: "COUNTRY",
    img: Location,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: leads_table_config.country.field,
  },
  {
    id: 12,
    label: "UTM INFO",
    icon: GpsFixed,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: leads_table_config.utm_info.field,
  },
];

// Customer Columns

export const allColumnsArrayCustomer = [
  {
    id: 0,
    label: "EMAIL",
    img: Email,
    disabled: true,
    isSelected: true,
    isLocked: true,
    value: customer_table_config.email.field,
  },
  {
    id: 1,
    label: "PHONE NO.",
    img: Call,
    disabled: true,
    isSelected: true,
    isLocked: true,
    value: customer_table_config.phone_number.field,
  },
  {
    id: 2,
    label: "TOTAL SPENT",
    img: Rupee,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: customer_table_config.total_spent.field,
  },
  {
    id: 3,
    label: "NO. OF TRANSACTIONS",
    img: NoOfTransaction,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: customer_table_config.transactions_count.field,
  },
  {
    id: 4,
    label: "LAST TRANSACTION DATE",
    img: LastTransaction,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: customer_table_config.last_transaction_date.field,
  },
  {
    id: 5,
    label: "SOURCE",
    img: Source,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: customer_table_config.referer_group_id.field,
  },
  {
    id: 6,
    label: "SOURCE LINK",
    img: Link,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: customer_table_config.source_link.field,
  },
  {
    id: 7,
    label: "RESPONSES",
    img: Responses,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: customer_table_config.answer_count.field,
  },
  {
    id: 8,
    label: "TAGS",
    img: Tags,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: customer_table_config.tags.field,
  },
  {
    id: 9,
    label: "COUNTRY",
    img: Location,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: customer_table_config.country.field,
  },
  {
    id: 10,
    label: "UTM INFO",
    icon: GpsFixed,
    disabled: false,
    isSelected: false,
    isLocked: false,
    value: customer_table_config.utm_info.field,
  },
];

export const parseColumnsArray = (columnsToShow, type) => {
  const columnToChange =
    type === constants.entity_type.leads.value
      ? allColumnsArrayLeads
      : allColumnsArrayCustomer;
  columnToChange.map((column) => {
    for (let i = 0; i < columnsToShow.length; i++) {
      if (columnsToShow[i] === column.value) {
        column.isSelected = true;
        column.initialIndex = i;
      }
    }
  });
};

export const onReset = async (type) => {
  try {
    const response = await updateColumns({
      is_reset: true,
      entity_type: type,
      columns_list: ["abc", "abc", "abc"],
    }); // send reset flag for update columns api
    if (response.status === 200) {
      window.location.reload(); //need to reload as we also need to fetch the new list of columns
    }
  } catch (err) {
    logError({
      error: err,
      when: "reseting columns",
      occuredAt:
        "webpage-leads/components/CrmModals/ManageColumnsModal/ManageColumnsHelpers.js",
    });
  }
};
